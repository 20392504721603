<template>
  <div>
    <v-row class="d-flex justify-center mx-1 mt-md-4 mt-1">
      <v-col cols="12" sm="10" xl="8">
        <h1 class="text-left mb-6" style="font-family: 'Times New Roman', Times, serif; font-size: 36px">Descargar Rosy IDE (versión 1.0)</h1>
        <v-row>
          <v-col
            cols="12" md="6"
            v-for="({ title, icon, color, text, to, zelda }, index) in items" :key="index"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                class="grey lighten-4"
                :class="hover ? 'ma-2' : ''"
                :elevation="hover ? 8 : 2"
              >
                <v-card-title :class="hover ? `${color} white--text` : ''">
                  <v-icon large left :color="hover ? 'white' : color" class="mr-4">{{ icon }}</v-icon>
                  {{ title }}
                </v-card-title>
                <v-card-text class="body-1 font-weight-medium mt-8 mb-2">
                  {{ text }}
                  <v-icon right color="blue-grey">fas fa-file-archive</v-icon>
                </v-card-text>
                <v-card-actions class="d-flex justify-end pa-4">
                  <v-btn
                    text
                    style="text-transform: none"
                    :color="color"
                    :to="to"
                  >
                    ¿Cómo instalar?
                  </v-btn>
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      dark
                      :color="color"
                      :href="zelda == 'w' ? winpus : linpus"
                    >
                      Descargar
                      <v-expand-x-transition>
                        <v-icon v-if="hover" right>fas fa-file-download</v-icon>
                      </v-expand-x-transition>
                    </v-btn>
                  </v-hover>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  title: 'Descargas',
  data: () => ({
    items: [
      {
        title: 'Windows',
        icon: 'fab fa-windows',
        color: 'blue',
        text: 'rosy-ide-1.0.zip',
        to: '/documentacion#windows',
        zelda: 'w'
      },
      {
        title: 'Debian / Ubuntu',
        icon: 'fab fa-ubuntu',
        color: 'orange',
        text: 'rosy-ide-1.0.tar.gz',
        to: '/documentacion#debian-ubuntu',
        zelda: 'l'
      },
    ],
  }),
  computed: {
    ...mapState(['winpus', 'linpus'])
  }
}
</script>
